import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";

import { OAuth, UserProvider } from "components/containers";
import { Main } from "components/molecules";
import { HomePage, TeamPage } from "components/templates";

import { apolloClient } from "./client";

const App: React.FC = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <UserProvider>
        <Main>
          <BrowserRouter>
            <Switch>
              <Route component={HomePage} path="/" exact />
              <Route component={OAuth} path="/oauth/" exact />
              <Route component={TeamPage} path="/:team" exact />
              <Redirect from="/" to="/" />
            </Switch>
          </BrowserRouter>
        </Main>
      </UserProvider>
    </ApolloProvider>
  );
};

export default App;
