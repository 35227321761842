import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      paddingTop: "2rem",
    },
    root: {
      paddingTop: "0.5rem",
      display: "flex",
      justifyContent: "left",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  })
);
