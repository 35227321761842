import React from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { IProps } from "./types";

export const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#fc5200",
    },
    secondary: {
      main: "#f0f0f5",
    },
  },
});

export const Main: React.FC<IProps> = ({ children }: IProps) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
