import React from "react";
import { User } from "components/containers/UserProvider/types/User";

export interface UserContextInterface {
  login: (code: string) => void;
  logout: () => void;
  openPopup: () => void;
  authorizationUrlLoading: boolean;
  tokenAuthLoading: boolean;
  tokenVerifyLoading: boolean;
  user: User | null;
}

/* eslint-disable @typescript-eslint/no-empty-function */
export const UserContext = React.createContext<UserContextInterface>({
  login: () => {},
  logout: () => {},
  openPopup: () => {},
  authorizationUrlLoading: false,
  tokenAuthLoading: false,
  tokenVerifyLoading: false,
  user: null,
});
/* eslint-enable @typescript-eslint/no-empty-function */

UserContext.displayName = "UserContext";
