import gql from "graphql-tag";

export const fragmentUser = gql`
  fragment User on User {
    id
    firstName
    lastName
    avatar
    teams {
      id
      name
      slug
    }
  }
`;

export const tokenCreateMutation = gql`
  ${fragmentUser}
  mutation TokenCreate($code: String!) {
    tokenCreate(code: $code) {
      user {
        ...User
      }
      token
    }
  }
`;

export const tokenVerifyMutation = gql`
  ${fragmentUser}
  mutation TokenVerify($token: String!) {
    tokenVerify(token: $token) {
      payload
      user {
        ...User
      }
    }
  }
`;

export const getAuthorizationUrl = gql`
  query AuthorizationUrl($redirectUrl: String!) {
    authorizationUrl(redirectUrl: $redirectUrl)
  }
`;
