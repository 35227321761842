import React from "react";
import { Box, Typography } from "@material-ui/core";

import { LAST_VISITED_TEAM_KEY } from "config";
import { useLocalStorage } from "hooks";
import { useStyles } from "./styles";
import { IProps } from "./types";

export const HomePage = ({ history }: IProps) => {
  const [storedTeamSlug] = useLocalStorage(LAST_VISITED_TEAM_KEY);
  const classes = useStyles();

  React.useEffect(() => {
    if (storedTeamSlug) {
      history.push(`/${storedTeamSlug}/`);
    }
  }, [storedTeamSlug, history]);

  if (storedTeamSlug) return null;

  return (
    <Box component="div" m={8} className={classes.root}>
      <Typography variant="h4">
        Leaderboard v.2{" "}
        <span role="img" aria-label="leaderboard">
          🏃
        </span>
      </Typography>
      <Typography variant="h5">Stay tuned</Typography>
    </Box>
  );
};
