import React from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { getAuthToken, setAuthToken, removeAuthToken } from "client";
import { OAUTH_REDIRECT_URL } from "config";
import { UserContext } from "context";
import { useOpenOAuthPopup } from "hooks";

import { IProps, IState } from "./types";
import {
  AuthorizationUrl,
  AuthorizationUrlVariables,
} from "./types/AuthorizationUrl";
import { TokenCreate, TokenCreateVariables } from "./types/TokenCreate";
import { TokenVerify, TokenVerifyVariables } from "./types/TokenVerify";
import {
  tokenCreateMutation,
  tokenVerifyMutation,
  getAuthorizationUrl,
} from "./queries";

export const UserProvider: React.FC<IProps> = ({
  children,
  onUserLogin,
  onUserLogout,
  persistToken = true,
}: IProps) => {
  const [authorizationUrl, setAuthorizationUrl] = React.useState<string>("");
  const [state, setState] = React.useState<IState>({
    token: getAuthToken(),
    user: null,
  });

  const { data, loading: authorizationUrlLoading } = useQuery<
    AuthorizationUrl,
    AuthorizationUrlVariables
  >(getAuthorizationUrl, {
    variables: { redirectUrl: OAUTH_REDIRECT_URL },
  });

  const [tokenCreate, { loading: tokenAuthLoading }] = useMutation<
    TokenCreate,
    TokenCreateVariables
  >(tokenCreateMutation);
  const [tokenVerify, { loading: tokenVerifyLoading }] = useMutation<
    TokenVerify,
    TokenVerifyVariables
  >(tokenVerifyMutation);

  const login = async (code: string) => {
    const { data } = await tokenCreate({ variables: { code } });

    if (data && data.tokenCreate) {
      const { token, user } = data.tokenCreate;
      setState(prevState => ({
        ...prevState,
        token,
        user,
      }));
      onUserLogin && onUserLogin();
    }
  };

  const logout = () => {
    setState(prevState => ({ ...prevState, token: null, user: null }));
    onUserLogout && onUserLogout();
  };

  const { openPopup } = useOpenOAuthPopup({
    authorizationUrl,
    onSuccess: ({ code }) => {
      login(code);
    },
  });

  React.useEffect(() => {
    if (data) {
      setAuthorizationUrl(data.authorizationUrl);
    }
  }, [data]);

  const authenticate = React.useCallback(
    async (token: string) => {
      const { data } = await tokenVerify({ variables: { token } });
      if (data && data.tokenVerify) {
        const { user } = data.tokenVerify;
        setState(prevState => ({
          ...prevState,
          user,
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          token: null,
        }));
      }
    },
    [tokenVerify]
  );

  React.useEffect(() => {
    if (state.token) {
      setAuthToken(state.token, persistToken);
      if (!state.user) {
        authenticate(state.token);
      }
    } else {
      removeAuthToken();
    }
  }, [state.token, state.user, persistToken, authenticate]);

  const context = {
    login,
    logout,
    openPopup,
    authorizationUrlLoading,
    tokenAuthLoading,
    tokenVerifyLoading,
    user: state.user,
  };
  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};
