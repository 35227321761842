import React from "react";
import { Button, CircularProgress } from "@material-ui/core";

import { useStyles } from "./styles";
import { IProps } from "./types";

export const MainButton = ({
  children,
  loading,
  outlined,
  onClick,
}: IProps) => {
  const classes = useStyles();

  return (
    <Button
      variant={outlined ? "outlined" : "contained"}
      color="primary"
      onClick={onClick}
      className={classes.link}
    >
      {loading ? (
        <CircularProgress
          color={outlined ? "primary" : "secondary"}
          size={24}
        />
      ) : (
        children
      )}
    </Button>
  );
};
