import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    content: {
      padding: "4.5rem 1rem 4.5rem 1rem",
    },
    ranking: {
      paddingBottom: "4rem",
    },
    sidebar: {
      paddingTop: "1rem",
    },
  })
);
