import React from "react";

import { IProps } from "./types";
import { Link } from "@material-ui/core";

import { useStyles } from "./styles";

export const StravaLink = ({ id, children }: IProps) => {
  const classes = useStyles();
  return (
    <Link
      className={classes.root}
      color="inherit"
      href={`https://www.strava.com/athletes/${id}`}
      noWrap={true}
      target="_blank"
      rel="noreferrer noopener"
    >
      {children}
    </Link>
  );
};
