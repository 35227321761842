import React from "react";
import { CircularProgress, Box } from "@material-ui/core";

import { useStyles } from "./styles";

export const DefaultLoader = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <CircularProgress color="primary" size={50} />
    </Box>
  );
};
