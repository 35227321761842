import gql from "graphql-tag";

export const fragmentTeam = gql`
  fragment Team on Team {
    id
    name
    slug
    users {
      id
      firstName
      avatar
    }
  }
`;

export const getTeam = gql`
  ${fragmentTeam}
  query TeamBySlug($slug: String!) {
    teamBySlug(slug: $slug) {
      ...Team
    }
  }
`;

export const joinTeamMutation = gql`
  ${fragmentTeam}
  mutation JoinTeam($team: ID!) {
    joinTeam(team: $team) {
      errors {
        field
        message
      }
      status
      team {
        ...Team
      }
    }
  }
`;

export const leaveTeamMutation = gql`
  ${fragmentTeam}
  mutation LeaveTeam($team: ID!) {
    leaveTeam(team: $team) {
      errors {
        field
        message
      }
      status
      team {
        ...Team
      }
    }
  }
`;

export const getMonthlyRanking = gql`
  query Month($team: ID!, $back: Int) {
    month(team: $team, back: $back) {
      dateRange {
        start
        stop
      }
      ranking {
        position
        athlete {
          id
          firstName
          avatar
        }
        distance
        activities
        longest
        avgPace
        elevationGain
      }
      summary {
        totalDistance
        totalActivities
      }
    }
  }
`;

export const getWeeklyRanking = gql`
  query Week($team: ID!, $back: Int) {
    week(team: $team, back: $back) {
      dateRange {
        start
        stop
      }
      ranking {
        position
        athlete {
          id
          firstName
          avatar
        }
        distance
        activities
        longest
        avgPace
        elevationGain
      }
      summary {
        totalDistance
        totalActivities
      }
    }
  }
`;
