import React from "react";
import { Box, Typography } from "@material-ui/core";

import { useStyles } from "./styles";

export const NotFound = () => {
  const classes = useStyles();
  return (
    <Box component="div" m={8} className={classes.root}>
      <Typography variant="h4">
        404 Not Found{" "}
        <span role="img" aria-label="404">
          😢
        </span>
      </Typography>
    </Box>
  );
};
