import React from "react";
import { Button } from "@material-ui/core";

import { useLogin } from "hooks";
import { useStyles } from "./styles";

export const LoginButton = () => {
  const { openPopup, logout, loading, user } = useLogin();
  const classes = useStyles();

  const onClick = () => (loading ? undefined : user ? logout() : openPopup());
  const label = loading
    ? "Loading..."
    : user
    ? "Logout"
    : "Connect with Strava";

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      className={classes.link}
    >
      {label}
    </Button>
  );
};
