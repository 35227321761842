import React from "react";
import QueryString from "query-string";

export const OAuth: React.FC = () => {
  React.useEffect(() => {
    const params = QueryString.parse(window.location.search);
    window.opener &&
      window.opener.postMessage(
        { ...params, provider: "oauth" },
        window.location.origin
      );
  });

  return null;
};
