import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  athlete: {
    display: "flex",
  },
  name: {
    lineHeight: "40px",
    marginLeft: 5,
  },
  rank: {
    paddingLeft: "1.5rem",
    paddingRight: 0,
  },
  header: {
    "& th": {
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "0.75rem",
      fontWeight: 500,
    },
  },
  selected: {
    backgroundColor: theme.palette.action.hover,
  },
  emptyCell: {
    textAlign: "center",
    padding: "2rem",
    color: theme.palette.text.disabled,
  },
}));
