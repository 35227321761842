import React from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Container, Grid } from "@material-ui/core";

import {
  DefaultLoader,
  NotFound,
  UserCard,
  MembersList,
} from "components/molecules";
import { Ranking } from "components/organisms";
import { useLocalStorage, useLogin } from "hooks";
import { useStyles } from "./styles";
import { MatchProps } from "./types";
import { TeamBySlug, TeamBySlugVariables } from "./types/TeamBySlug";
import { Month, MonthVariables } from "./types/Month";
import { Week, WeekVariables } from "./types/Week";
import {
  getTeam,
  getMonthlyRanking,
  getWeeklyRanking,
  joinTeamMutation,
  leaveTeamMutation,
} from "./queries";
import { LAST_VISITED_TEAM_KEY } from "config";
import { LoginButton, MainButton } from "components/atoms";
import { JoinTeam, JoinTeamVariables } from "./types/JoinTeam";
import { LeaveTeam, LeaveTeamVariables } from "./types/LeaveTeam";

export const TeamPage = ({ match }: MatchProps) => {
  const [teamId, setTeamId] = React.useState<string>();
  const [storedTeamSlug, setTeamSlug] = useLocalStorage(LAST_VISITED_TEAM_KEY);
  const { user, logout } = useLogin();
  const classes = useStyles();
  const { team: teamSlug } = match.params;

  const { loading, data } = useQuery<TeamBySlug, TeamBySlugVariables>(getTeam, {
    variables: { slug: teamSlug },
  });
  const { data: monthData, loading: loadingMonth } = useQuery<
    Month,
    MonthVariables
  >(getMonthlyRanking, {
    skip: !teamId,
    variables: {
      team: teamId as string,
      back: 0,
    },
  });
  const { data: weekData, loading: loadingWeek } = useQuery<
    Week,
    WeekVariables
  >(getWeeklyRanking, {
    skip: !teamId,
    variables: {
      team: teamId as string,
      back: 0,
    },
  });
  const manageTeamOptions = {
    refetchQueries: ["Month", "Week"],
    awaitRefetchQueries: true,
  };
  const [joinTeam, { loading: joinTokenLoading }] = useMutation<
    JoinTeam,
    JoinTeamVariables
  >(joinTeamMutation, manageTeamOptions);
  const [leaveTeam, { loading: leaveTeamLoading }] = useMutation<
    LeaveTeam,
    LeaveTeamVariables
  >(leaveTeamMutation, manageTeamOptions);

  React.useEffect(() => {
    if (data && data.teamBySlug) {
      setTeamId(data.teamBySlug.id);
      setTeamSlug(teamSlug);
    } else {
      setTeamId(undefined);
      if (storedTeamSlug === teamSlug) {
        setTeamSlug(null);
      }
    }
  }, [data, setTeamSlug, storedTeamSlug, teamSlug]);

  if (loading && !data) return <DefaultLoader />;
  if (!data || !data.teamBySlug) return <NotFound />;

  const monthlyRows = monthData ? monthData.month.ranking : [];
  const weeklyRows = weekData ? weekData.week.ranking : [];
  const athleteId = user ? user.id : undefined;
  const joined = data.teamBySlug.users.find(
    (athlete: { id: string }) => athlete.id === athleteId
  );

  const joinTeamAction = async () =>
    await joinTeam({ variables: { team: teamId as string } });
  const leaveTeamAction = async () =>
    await leaveTeam({ variables: { team: teamId as string } });

  return (
    <Container maxWidth="lg" className={classes.content}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <div className={classes.ranking}>
            <Ranking
              rows={monthlyRows}
              title="This Month's Leaderboard"
              selected={athleteId}
              loading={loadingMonth}
            />
          </div>
          <div className={classes.ranking}>
            <Ranking
              rows={weeklyRows}
              title="This Week's Leaderboard"
              selected={athleteId}
              loading={loadingWeek}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          {user ? (
            <React.Fragment>
              <UserCard user={user} onLogout={logout} />
              <div className={classes.sidebar}>
                <MainButton
                  loading={joinTokenLoading || leaveTeamLoading}
                  outlined={!!joined}
                  onClick={joined ? leaveTeamAction : joinTeamAction}
                >
                  {joined ? "Team Joined" : "Join Team"}
                </MainButton>
              </div>
            </React.Fragment>
          ) : (
            <LoginButton />
          )}
          <MembersList users={data.teamBySlug.users} />
        </Grid>
      </Grid>
    </Container>
  );
};
