import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Avatar,
} from "@material-ui/core";

import { StravaLink } from "components/atoms";
import { useStyles } from "./styles";
import { IProps } from "./types";

export const UserCard = ({ user, onLogout }: IProps) => {
  const classes = useStyles();

  if (!user) return null;

  return (
    <div className={classes.wrapper}>
      <Card className={classes.root}>
        <CardContent>
          <div className={classes.athlete}>
            <Avatar
              alt={user.firstName}
              src={user.avatar}
              className={classes.large}
            />
          </div>

          <Typography className={classes.title} variant="h6">
            <StravaLink id={user.id}>
              {user.firstName} {user.lastName}
            </StravaLink>
          </Typography>
          <Button size="small" onClick={onLogout}>
            Logout
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};
