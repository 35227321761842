import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
  },
  root: {
    textAlign: "center",
  },
  athlete: {
    position: "absolute",
    marginTop: "-3.5rem",
    left: "50%",
    transform: "translate(-50%, 0)",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    padding: "2rem 0 1.5rem 0",
  },
  pos: {
    marginBottom: 12,
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    boxShadow: "0rem 0.6rem 2rem rgba(19, 56, 111, 0.26)",
  },
}));
