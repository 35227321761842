import React from "react";

const POPUP = {
  width: 600,
  height: 600,
};

interface IParams {
  authorizationUrl: string;
  state?: string;
  onFailure?: (data: unknown) => void;
  onSuccess?: ({ code }: { code: string }) => void;
}

export const useOpenOAuthPopup = ({
  authorizationUrl,
  state = "",
  onFailure,
  onSuccess,
}: IParams) => {
  const [popup, setPopup] = React.useState<Window | null>(null);

  const openPopup = () => {
    const left = window.innerWidth / 2 - POPUP.width / 2;
    const top = window.innerHeight / 2 - POPUP.height / 2;
    setPopup(
      window.open(
        authorizationUrl,
        "",
        `toolbar=no, location=no, directories=no, status=no, menubar=no, 
        scrollbars=no, resizable=no, copyhistory=no, width=${POPUP.width}, 
        height=${POPUP.height}, top=${top}, left=${left}`
      )
    );
  };

  const closePopup = React.useCallback(() => {
    popup && popup.close();
  }, [popup]);

  React.useEffect(() => {
    const receiveMessage = (event: MessageEvent) => {
      if (
        event.origin === window.location.origin &&
        event.data.provider === "oauth"
      ) {
        if (event.data.state === state) {
          if (event.data.error) {
            onFailure && onFailure(event.data);
          } else if (event.data.code) {
            onSuccess && onSuccess({ code: event.data.code });
          }
          closePopup();
        }
      }
    };

    window.addEventListener("message", receiveMessage, false);
    return () => {
      window.removeEventListener("message", receiveMessage, false);
    };
  }, [popup, onSuccess, onFailure, state, closePopup]);

  return { openPopup, closePopup };
};
