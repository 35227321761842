import { useContext } from "react";

import { UserContext } from "context";

export const useLogin = () => {
  const {
    openPopup,
    logout,
    tokenAuthLoading,
    tokenVerifyLoading,
    user,
  } = useContext(UserContext);
  return {
    openPopup,
    logout,
    loading: tokenAuthLoading || tokenVerifyLoading,
    user,
  };
};
