import React from "react";
import { Avatar, Chip, Typography } from "@material-ui/core";

import { useStyles } from "./styles";
import { IProps } from "./types";

export const MembersList = ({ users }: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Typography variant="h6">Members</Typography>
      <div className={classes.root}>
        {users.map(({ firstName, avatar }, index) => (
          <Chip
            key={index}
            avatar={<Avatar alt={firstName} src={avatar} />}
            label={firstName}
          />
        ))}
      </div>
    </div>
  );
};
