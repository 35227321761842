import React from "react";
import { RankingTable } from "components/molecules";
import { Paper, Toolbar, Typography, Box } from "@material-ui/core";

import { useStyles } from "./styles";
import { IProps } from "./types";

export const Ranking = ({ title, ...props }: IProps) => {
  const classes = useStyles();
  return (
    <Paper>
      <Toolbar>
        <Typography variant="h6">{title}</Typography>
      </Toolbar>
      <Box className={classes.table}>
        <RankingTable {...props} />
      </Box>
    </Paper>
  );
};
