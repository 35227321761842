import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";

import { DefaultLoader } from "components/molecules/DefaultLoader";
import { useStyles } from "./styles";
import {
  IOrder,
  IColumn,
  ISortableColumn,
  IProps,
  EmptyRowProps,
} from "./types";
import { getComparator, stableSort } from "./utils";

const sortableColumns: IColumn[] = [
  { id: "distance", label: "Distance" },
  { id: "activities", label: "Activities" },
  { id: "longest", label: "Longest" },
  { id: "avgPace", label: "Avg. Pace" },
  { id: "elevationGain", label: "Elev. Gain" },
];

const EmptyRow = ({ children }: EmptyRowProps) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell
        colSpan={sortableColumns.length + 2}
        className={classes.emptyCell}
      >
        {children}
      </TableCell>
    </TableRow>
  );
};

export const RankingTable = ({ rows = [], selected, loading }: IProps) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState<IOrder>("desc");
  const [orderBy, setOrderBy] = React.useState<ISortableColumn>("distance");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: ISortableColumn
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: ISortableColumn) => (
    event: React.MouseEvent<unknown>
  ) => {
    handleRequestSort(event, property);
  };

  const renderRows = () =>
    stableSort(rows, getComparator(order, orderBy)).map((row, rank) => (
      <TableRow
        key={row.athlete.id}
        className={selected === row.athlete.id ? classes.selected : undefined}
      >
        <TableCell className={classes.rank}>{rank + 1}</TableCell>
        <TableCell component="th" scope="row">
          <div className={classes.athlete}>
            <Avatar alt={row.athlete.firstName} src={row.athlete.avatar} />
            <span className={classes.name}>{row.athlete.firstName}</span>
          </div>
        </TableCell>
        <TableCell>{row.distance} km</TableCell>
        <TableCell>{row.activities}</TableCell>
        <TableCell>{row.longest} km</TableCell>
        <TableCell>{row.avgPace} / km</TableCell>
        <TableCell>{parseFloat(row.elevationGain.toFixed(1))} m</TableCell>
      </TableRow>
    ));

  const renderContent = () =>
    rows.length ? (
      renderRows()
    ) : (
      <EmptyRow>Too early to see any statistics</EmptyRow>
    );

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="Ranking" size="small">
        <TableHead>
          <TableRow className={classes.header}>
            <TableCell className={classes.rank}>Rank</TableCell>
            <TableCell className={classes.rank}>Athlete</TableCell>
            {sortableColumns.map(column => (
              <TableCell key={column.id}>
                <Tooltip
                  enterDelay={300}
                  placement="bottom-start"
                  title={`Sort by ${column.label}`}
                >
                  <TableSortLabel
                    active={column.id === orderBy}
                    direction={column.id === orderBy ? order : "asc"}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <EmptyRow>
              <DefaultLoader />
            </EmptyRow>
          ) : (
            renderContent()
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
